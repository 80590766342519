.banner-img {
  margin-top: 10px;
}

.home-page h1 {
  color: gray;
  font-family: "Playfair Display", serif;
}

.home-page {
  display: flex;
  flex-wrap: wrap;
  /* margin-left: 8vmax; */

}
.home-page .card {
  background-color: rgba(128, 128, 128, 0.097);
  width: 8rem;
  
  
  
}
.home-page .card-name-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 11rem;
  background-color:#2e5d8b;
}

.home-page .card-name-price button {
  color: white; /* Set the text color to white */
  padding: 0.1rem 0.1rem 0.1rem 0.1rem; /* Adjust the padding to make the button smaller */
  font-size: 0.9rem; /* Adjust the font size to make the button smaller */
}

.card-body {
  display: flex;
  flex-direction: column;
  margin-left: 6vmax;
  
}

.home-page .card-price {
  color: green;
  font-weight: bold;
}
.home-page button {
  border-radius: 0;
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}

.home-page .card-text {
  color: red;
  font-weight: bold;
  
}

.home-page .card img:hover {
  transform: scale(0.9);
}

.home-page .card-img-top {
  height: 150px !important;
}
/* ==================
filters
============== */
.filters {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align items horizontally at the center */
  align-items: center; /* Align items vertically at the center */
  width: 85%;
  margin-left: 8vmax;
}
.filters h5 {
  /* margin-top: 100px;  */
  color: gray !important;
  border-bottom: 1px solid rgba(128, 128, 128, 0.337);
  text-align: center; 
}

.ant-checkbox-wrapper:first-of-type {
  margin-left: 8px;
}

.ant-checkbox-wrapper {
  margin: 2px;
}
.ant-radio-wrapper {
  margin-bottom: 5px !important;
  margin-left: 10px;
}

.filters button {
  background-color: rgb(61, 97, 255);
  width: 100%;
  border: none;
  border-radius: 0;
  margin-top: 50px;
}

.loadmore {
  color: green;
  font-weight: bold;
  font-size: 20px !important;
}

.radio-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}



.radio-group {
  display: flex;
   /* Allow wrapping of radio buttons if they exceed the width */
}

.radio-item {
  margin-right: 10px; /* Adjust as needed for spacing between radio buttons */
}

.checkbox-item {
  margin-bottom: 10px; /* Adjust as needed for spacing between checkboxes */
}

.card-container {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  border: 1px solid #ddd;
  border-radius: 5px;
  

}

.card-img-left {
  flex-shrink: 0; /* Ensure the image does not shrink */
  width: 150px;
  height: auto;
  margin-right: 10px;
  
  
}

.card-content {
  flex: 1 1 auto; /* Grow, shrink, and basis equally */
}


/* Apply media query for screens smaller than 768px (typical tablets) */
@media screen and (min-width: 577px) and (max-width: 768px) {
  .radio-item {
    margin-bottom: 5px; /* Adjust spacing between radio buttons for smaller screens */
  }
  .home-page {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0.1vmax;
  
  }
  .filters {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align items horizontally at the center */
  align-items: center; /* Align items vertically at the center */
  width: 100%;
  margin-top: 4vmax;

}
.home-page .card-container {
  width: 45%; /* Adjust card width for medium screens */
}
.home-page .card-name-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 9rem;

}
}

/* Apply media query for screens smaller than 576px (typical smartphones) */
@media screen and (max-width: 576px) {
  .radio-item {
    margin-right: 0; /* Remove right margin for radio buttons */
    margin-bottom: 5px; /* Adjust spacing between radio buttons for smaller screens */
  }
  .filters h5 {
    text-align: center; /* Align text in the center for small screens */
  }
  .filters {
    width: 100%; /* Adjust width to fit smaller screens */
    margin-left: 1vmax;
    margin-top: 8vmax;
    /* Remove margin for small screens */
  }
  .home-page .card-container {
    width: 100%; /* Adjust card width for medium screens */
    
  }
  .home-page {
     /* Adjust card width for medium screens */
    margin-left: 0.1vmax;

  }
  .home-page .card-name-price {
    width: 40%; /* Make card name and price full width on small screens */
    justify-content: space-around; /* Adjust spacing for small screens */
    margin-right: 1vmax;
  }
  .home-page h1 {
    margin-left: 4vmax;
  }
}

/* Adjustments for large screens */
@media screen and (min-width: 769px) {
  .home-page .card-container {
    width: 30%; /* Adjust card width for large screens */
    margin-left: 4vmax;

  }
  .home-page .card-name-price {
    display: flex;
    justify-content: flex-end; /* Align items to the end (right) of the container */
    width: 40%; /* Ensure the container takes up full width */
    position: relative;  /* Adjust spacing for small screens */
    margin-left: 2vmax;
  }
  .home-page h1 {
    margin-left: 2vmax;
  }
  .filters h5 {
    text-align: center; /* Align text in the center for small screens */
  }
  .filters {
    width: 100%; /* Adjust width to fit smaller screens */
    margin-left: 1vmax;

    /* Remove margin for small screens */
  }
  .cardd{
    margin-left: 7vmax;

  }
}