.product-details {
  margin-top: 100px !important;
}

.product-details-info {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.product-details-info h6 {
  margin-bottom: 20px !important;
}

.similar-products .card {
  background-color: rgba(128, 128, 128, 0.097);
  width: 18rem;
}

.similar-products .card-name-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.similar-products .card-price {
  color: green;
  font-weight: bold;
}
.similar-products button {
  border-radius: 0;
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}

.similar-products .card-text {
  color: rgb(90, 89, 89);
}

.similar-products .card img:hover {
  transform: scale(0.9);
}

.similar-products .card-img-top {
  height: 300px !important;
}
