@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:wght@300;400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* font-family: 'Poppins', sans-serif; */
/* font-family: 'Playfair Display', serif; */

/* //navbar css */
.navbar {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: azure;

  /* text-transform: uppercase; */
  /* box-shadow: 0 8px 6px -6px gray; */
  /* --webkit-box-shadow: 0 8px 6px -6px gray; */
  /* border-bottom: solid gray !important; */
}

.bg-custom-color {
  background-color: #2e5d8b; /* Replace "your-color" with the desired color */
}

.nav-link {
  font-weight: 300 !important;
  color: azure;

}

.active {
  color: azure;

}

.navbar-brand {
  font-weight: 400;
  font-family: "Satisfy";
  color: azure;
  letter-spacing: 1px;
}

.search-form {
  margin-left: 10px;
  margin-top: 4px;
}

.search-form input {
  border: none;
  border-radius: 0;
}
.search-form button {
  /* background-color: rgb(61, 97, 255); */
  border-radius: 0;
  color: white;
}

/* =============================
==========footer============= */

.footer {
  color: white;
  padding: 25px;
  background: #000000; /* fallback for old browsers */
  background-color: #2e5d8b; 
}

.footer a {
  text-decoration: none;
  color: white;
  padding: 10px;
}

.footer a:hover {
  color: #ffefba;
  border-bottom: 1px solid #ffefba;
}
/* =================================
======== page not found css ======= */
.pnf {
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pnf-title {
  font-size: 100px;
  font-weight: 700;
}

.pnf-heading {
  font-weight: normal;
}

.pnf-btn {
  color: black;
  border: 1px solid black;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
}
.pnf-btn:hover {
  background-color: black;
  color: white;
}
/* ========================================= */
/* =========contact us ========= */
.contactus {
  margin: 70px;
  padding: 10;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disclosure {
  margin-top: 10px;
  padding-top: 10; 
  height: 260vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ==================== */
.product-link {
  text-decoration: none !important;
  color: black !important;
}

.cat-btn {
  padding: 40px 0px;
  font-size: 24px;
  text-transform: uppercase;
}
.cat-btn:hover {
  background-color: #434343;
  color: white;
}
/* ================
====dashboard
=================== */

.dashboard {
  margin-top: 100px !important;
}

.dashboard-menu h4 {
  background-color: #434343 !important;
  color: white;
  padding: 20px 0px;
}
